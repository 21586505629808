import './utils';
import './main-jquery.js';
import './scrollTrigger';
import './mortgageTableManager';

class Main {
  constructor() {
    this.DOM = {
      body: document.body,
      buttonMenu: document.querySelector('.navbar-toggler'),
      menuNav: document.querySelector('.menu-main-nav'),
      menuBlocks: document.querySelectorAll('.menu-main-block'),
    };

    this.titleIndex = 0;
    this.logCredits();
    this.startIntro();
    this.setMenu();
  }

  startIntro() {
    setTimeout(() => {
      this.DOM.body.classList.add('start-page-trigger');
    }, 100);
  }

  logCredits() {
    if (window.console && console.log) {
      console.log('Made by www.webworx.nl');
    }
  }

  toggleMenu(force) {
    this.DOM.body.classList.toggle('menu-open', force);
    this.DOM.buttonMenu.classList.toggle('collapsed');

    // Modify overflow-y property of <html>
    if (this.DOM.body.classList.contains('menu-open')) {
      document.documentElement.style.overflowY = 'hidden';
    } else {
      document.documentElement.style.overflowY = '';
    }
  }

  openMenu() {
    this.DOM.body.classutils.jsList.remove('menu-open');
  }

  closeMenu() {
    this.DOM.body.classList.remove('menu-open');
  }

  setMenu() {
    this.DOM.buttonMenu.addEventListener('click', () => this.toggleMenu());
  }
}

document.addEventListener('DOMContentLoaded', function () {
  new Main();
});

class ParallaxEffect {
  constructor() {
    this.advicesContent = document.querySelector('.s-advices__content');
    this.advicesSection = document.querySelector('.s-advices');
    this.maxUpMovement = -80;
    this.maxDownMovement = 75;

    window.addEventListener('scroll', () => this.applyParallax());
  }

  applyParallax() {
    const sectionTop = this.advicesSection.offsetTop;
    const sectionHeight = this.advicesSection.offsetHeight;
    const scrolled = window.pageYOffset;

    const relativeScrollStart = Math.max(sectionTop - window.innerHeight, 0);
    const relativeScroll = scrolled - relativeScrollStart;

    // Calculate the percentage of the section that's been scrolled
    const scrollRange = sectionHeight + window.innerHeight;
    const scrollPercent = Math.max(
      0,
      Math.min(1, relativeScroll / scrollRange)
    );

    // Calculate the total possible movement range
    const totalMovementRange = this.maxDownMovement - this.maxUpMovement;

    let parallax = this.maxUpMovement + totalMovementRange * scrollPercent;

    parallax = Math.min(
      Math.max(parallax, this.maxUpMovement),
      this.maxDownMovement
    );

    // Apply the transformation if the section is currently in the viewport
    if (
      scrolled + window.innerHeight > sectionTop &&
      scrolled < sectionTop + sectionHeight
    ) {
      this.advicesContent.style.transform = `translate3d(0,${parallax}px, 0)`;
    }
  }
}

document.addEventListener('DOMContentLoaded', function () {
  new ParallaxEffect();
});
