// Import everything from autoload folder
import './autoload/_bootstrap.js'; import './autoload/ada-compliance.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
//import './plugins/google-map';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
/* global Swiper */

//import { Fancybox } from '@fancyapps/ui';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  $('.gallery-item')
    .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
    .attr('rel', 'gallery')
    .attr('data-fancybox', 'gallery');

  //Fancybox.bind('[data-fancybox]');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    // Select all input and textarea elements
    const fields = document.querySelectorAll('input, textarea');

    fields.forEach((el) => {
      el.dataset.holder = el.getAttribute('placeholder');

      el.addEventListener('focusin', () => {
        el.setAttribute('placeholder', '');
      });

      el.addEventListener('focusout', () => {
        el.setAttribute('placeholder', el.dataset.holder);
      });
    });
  };

  removeFieldPlaceholder();

  document.addEventListener('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Override bootstrap submenu toggle
   */
  $('.navbar .menu-item-has-children').hover(function () {
    if ($(window).width() > 991) {
      $(this).toggleClass('show');
      alignMenuDropdown($(this).children('.dropdown-menu'));
    }
  });

  // Collapse on hover
  $('.js-collapse-link').hover(function () {
    let $parent = $(this).closest('.js-collapse-links');
    $parent.find('.js-collapse-link').attr('aria-expanded', false);

    $(this).attr('aria-expanded', true);
    $($(this).data('target')).collapse('show');
  });

  // Custom scroll navigation
  $(document).on('click', '.js-scroll-prev', function () {
    let $scrollArea = $(this).closest('section').find('.js-scroll-area');
    scrollBlock($scrollArea, -400);
  });

  $(document).on('click', '.js-scroll-next', function () {
    let $scrollArea = $(this).closest('section').find('.js-scroll-area');
    scrollBlock($scrollArea);
  });

  function scrollBlock($target, $offset = 400) {
    if (typeof $target !== undefined && $target.length) {
      $target.animate({ scrollLeft: $target.scrollLeft() + $offset }, 300);
    }
    return false;
  }

  // Prevent dropdown menu opening out of window
  function alignMenuDropdown($dropdown) {
    if ($dropdown === undefined || !$dropdown.length) return false;
    $dropdown.toggleClass(
      'dropdown-menu-left',
      $dropdown.offset().left + $dropdown.width() > $(window).width()
    );
  }

  // Dropdown menu mobile toggle
  $('.js-dropdown-toggle').bind('click', function (e) {
    e.preventDefault();
    e.stopPropagation();

    if ($(window).width() <= 991) {
      $(this).closest('.dropdown').toggleClass('show');
    }
  });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    // jQuery code goes here
  });

  $(window).trigger('scroll');

  // Init Swiper
  if ($('.js-news-slider').length && typeof Swiper !== undefined) {
    const swiper = new Swiper('.js-news-slider', {
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      speed: 500,
      spaceBetween: 30,
      slidesPerView: 1.5,
      breakpoints: {
        576: {
          slidesPerView: 2.5,
        },
        1200: {
          slidesPerView: 3.1,
          spaceBetween: 50,
        },
        1980: {
          slidesPerView: 3.1,
        },
      },
    });

    console.log(swiper.initialized);
  }

  let $testimonials = $('.js-testimonials-slider');
  if ($testimonials.length && typeof Swiper !== undefined) {
    const testimonialSwiper = new Swiper('.js-testimonials-slider', {
      speed: 2000,
      spaceBetween: 0,
      slidesPerView: 1,
      pagination: {
        clickable: true,
      },
    });

    // Custom bullets navigation
    $(document).on('click', '.js-swiper-bullet', (el) => {
      testimonialSwiper.slideTo($(el.target).data('slide'));
    });
  }

  // See more toggle
  $(document).on('click', '.js-see-more', (e) => {
    e.preventDefault();
    $(e.currentTarget)
      .toggleClass('is-open')
      .siblings('.js-more-content')
      .slideToggle();
  });

  resizeVideo();
});
/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
let lastScrollTop = 0;
$(window).on('scroll', function () {
  // jQuery code goes here
  let st = $(this).scrollTop();
  $('body')
    .toggleClass('scrolling-top', st < lastScrollTop)
    .toggleClass('scrolling-bottom', st > lastScrollTop)
    .toggleClass('scrolling', st !== 0);
  if (st === 0) $('body').removeClass('scrolling-top scrolling-bottom');
  lastScrollTop = st;
});

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}
