// import { DomUtils } from './utils';

class ScrollAnimations {
  constructor() {
    this.elements = document.querySelectorAll('.set-in-view');
    this.checkIfInView();
    window.addEventListener('scroll', () => this.checkIfInView()); // Set up the scroll event listener
  }

  onEnter(element) {
    if (!element.classList.contains('is-in-view')) {
      void element.offsetHeight;
      element.classList.add('is-in-view');
    }
  }

  checkIfInView() {
    const windowHeight = window.innerHeight;
    this.elements.forEach((element) => {
      const elementTop = element.getBoundingClientRect().top;
      const elementBottom = element.getBoundingClientRect().bottom;

      const isElementPartiallyVisible =
        elementTop < windowHeight && elementBottom >= 0;

      if (isElementPartiallyVisible) {
        this.onEnter(element);
      }
    });
  }
}

document.addEventListener('DOMContentLoaded', () => {
  new ScrollAnimations();
});

class GradientFollower {
  constructor(selector) {
    this.element = document.querySelector(selector);
    this.targetX = 50;
    this.targetY = 50;
    this.currentX = 50;
    this.currentY = 50;
    this.ease = 0.009;
    this.sensitivity = 7;
    this.maxMovement = 500;

    if (this.element) {
      this.bindEvents();
      this.animate();
    }
  }

  bindEvents() {
    this.element.addEventListener('mousemove', (e) => this.updateTarget(e));
  }

  updateTarget(e) {
    const rect = this.element.getBoundingClientRect();
    // Scale down the changes by the sensitivity factor
    this.targetX +=
      (((e.clientX - rect.left) / rect.width) * 100 - this.targetX) /
      this.sensitivity;
    this.targetY +=
      (((e.clientY - rect.top) / rect.height) * 100 - this.targetY) /
      this.sensitivity;
  }

  animate() {
    this.currentX += (this.targetX - this.currentX) * this.ease;
    this.currentY += (this.targetY - this.currentY) * this.ease;

    // Calculate the angle for the gradient
    let angleDeg =
      (Math.atan2(this.currentY - 50, this.currentX - 50) * 180) / Math.PI + 90;

    // Constrain the angle movement to a maximum range
    angleDeg = this.constrainAngle(angleDeg);

    // Update the gradient
    const gradientStyle = `linear-gradient(${angleDeg}deg, rgba(47,172,102,.33), rgb(47 172 102))`;
    this.element.style.backgroundImage = gradientStyle;

    requestAnimationFrame(() => this.animate());
  }

  constrainAngle(angle) {
    // Ensure the angle doesn't change more than the maxMovement from the initial position (90 degrees)
    const initialAngle = 90;
    const minAngle = initialAngle - this.maxMovement;
    const maxAngle = initialAngle + this.maxMovement;
    return Math.min(Math.max(angle, minAngle), maxAngle);
  }
}

new GradientFollower('.s-home-intro__wrapper');

class ScalerElement {
  constructor(selector) {
    this.element = document.querySelector(selector);
    this.handleScroll = this.handleScroll.bind(this);
    window.addEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    if (!this.element) return;

    const elementRect = this.element.getBoundingClientRect();
    const windowHeight =
      window.innerHeight || document.documentElement.clientHeight;

    // Check if the element is in the viewport
    if (elementRect.bottom > 0 && elementRect.top < windowHeight) {
      const middleScreen = windowHeight / 2;
      const middleElement = elementRect.top + elementRect.height / 2;
      const distanceFromMiddle = Math.abs(middleScreen - middleElement);

      // Normalize the distance and adjust the scale factor for width only
      const normalizedDistance = Math.min(distanceFromMiddle / middleScreen, 1);
      const scaleX = 1 + normalizedDistance * 0.04;

      // Apply the scaling only to the X-axis
      this.element.style.transform = `translate(-50%, -50%) scaleX(${scaleX})`;
    }
  }
}

document.addEventListener('DOMContentLoaded', () => {
  if (window.innerWidth >= 992) {
    new ScalerElement('.s-cta__background');
  }
});
