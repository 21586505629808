export const MathUtils = {
  // map number x from range [a, b] to [c, d]
  map: (x, a, b, c, d) => ((x - a) * (d - c)) / (b - a) + c,
  // linear interpolation
  lerp: (a, b, n) => (1 - n) * a + n * b,
};

export const DeviceUtils = {
  isMobileDevice: () => {
    return (
      typeof window.orientation !== 'undefined' ||
      navigator.userAgent.indexOf('IEMobile') !== -1
    );
  },
};

export const DomUtils = {
  elementPresent: (element) => {
    const style = getComputedStyle(element);
    const display = style.display;
    return display !== 'none';
  },
  getViewportWidth: () => {
    return document.documentElement.clientWidth;
  },
};
