class MortgageTableManager {
  constructor() {
    this.mortgageHeaders = document.querySelectorAll(
      '.matchHeight.mortgages th[data-mortgage-id]'
    );
    this.dropdownMenuMortgages = document.querySelector(
      '.checkbox-dropdown-menu-mortgages'
    );
    this.attributeCheckboxes = document.querySelectorAll(
      '.checkbox-dropdown-menu-attributes input[type="checkbox"]'
    );
    this.maxCheckedMortgages = 3;
    this.comparatorInner = document.querySelector('.s-comparator__inner'); // Reference to the comparator inner div

    this.comparatorInner.style.maxHeight = '0px'; // Initially collapse the table
    this.init();
  }

  init() {
    this.setupMortgageCheckboxes();
    this.setupAttributeCheckboxes();
    this.setupDropdownToggles();
    this.updateTableDisplay(); // Initial call to set the correct state
  }

  setupDropdownToggles() {
    document.querySelectorAll('.dropdown-toggle').forEach((toggleButton) => {
      toggleButton.addEventListener('click', () => {
        const dropdownMenu = toggleButton.nextElementSibling;
        if (dropdownMenu.classList.contains('expanded')) {
          dropdownMenu.classList.remove('expanded');
          dropdownMenu.style.maxHeight = null;
        } else {
          dropdownMenu.classList.add('expanded');
          dropdownMenu.style.maxHeight = dropdownMenu.scrollHeight + 'px';
        }
      });
    });
  }

  setupMortgageCheckboxes() {
    this.mortgageHeaders.forEach((header) => {
      const mortgageId = header.getAttribute('data-mortgage-id');
      const listItem = document.createElement('li');
      const checkbox = document.createElement('input');
      checkbox.type = 'checkbox';
      checkbox.id = 'mortgage-' + mortgageId;
      checkbox.value = mortgageId;

      const label = document.createElement('label');
      label.htmlFor = checkbox.id;
      label.textContent = header.textContent;

      checkbox.addEventListener('change', () => {
        if (
          this.dropdownMenuMortgages.querySelectorAll(
            'input[type="checkbox"]:checked'
          ).length > this.maxCheckedMortgages
        ) {
          alert(
            'You can select up to ' + this.maxCheckedMortgages + ' mortgages.'
          );
          checkbox.checked = false;
          return;
        }
        this.updateTableDisplay();
      });

      listItem.appendChild(checkbox);
      listItem.appendChild(label);
      this.dropdownMenuMortgages.appendChild(listItem);
    });
  }

  setupAttributeCheckboxes() {
    this.attributeCheckboxes.forEach((checkbox, index) => {
      checkbox.checked = false;
      checkbox.id = 'attribute-' + (index + 1); // Set a unique ID for each checkbox

      // Find an existing label or create a new one
      let label = checkbox.nextElementSibling;
      if (!label || label.tagName !== 'LABEL') {
        label = document.createElement('label');
        label.htmlFor = checkbox.id; // Associate the label with the checkbox
        checkbox.parentNode.appendChild(label); // Append the label after the checkbox
      }

      checkbox.addEventListener('change', () => {
        const checkedAttributeBoxes = document.querySelectorAll(
          '.checkbox-dropdown-menu-attributes input[type="checkbox"]:checked'
        );
        if (checkedAttributeBoxes.length > 3) {
          alert('You can select up to 3 attributes.');
          checkbox.checked = false;
          return;
        }
        this.updateTableDisplay();
      });

      checkbox.parentNode.appendChild(label);
    });
  }

  updateTableDisplay() {
    // Handle mortgage checkboxes
    this.mortgageHeaders.forEach((header) => {
      const mortgageId = header.getAttribute('data-mortgage-id');
      this.toggleColumn(mortgageId, false);
    });

    const checkedMortgageCheckboxes =
      this.dropdownMenuMortgages.querySelectorAll(
        'input[type="checkbox"]:checked'
      );
    Array.from(checkedMortgageCheckboxes)
      .slice(0, this.maxCheckedMortgages)
      .forEach((checkbox) => {
        this.toggleColumn(checkbox.value, true);
      });

    // Handle attribute checkboxes
    this.attributeCheckboxes.forEach((checkbox, index) => {
      const attributeRow = document.querySelector(
        `tr[data-attribute="attribute-${index + 1}"]`
      );
      if (attributeRow) {
        attributeRow.style.display = checkbox.checked ? 'table-row' : 'none';
      }
    });

    // Toggle the visibility of the comparator inner div
    if (
      checkedMortgageCheckboxes.length > 0 ||
      Array.from(this.attributeCheckboxes).some((cb) => cb.checked)
    ) {
      this.comparatorInner.classList.add('visible');
      this.adjustTableHeight(); // Adjust the height based on visible content
    } else {
      this.comparatorInner.classList.remove('visible');
      this.comparatorInner.style.maxHeight = '0'; // Or set to a minimum height
    }
  }

  adjustTableHeight() {
    let totalHeight = 0;
    this.comparatorInner.querySelectorAll('tr').forEach((row) => {
      if (row.style.display !== 'none') {
        totalHeight += row.offsetHeight;
      }
    });
    this.comparatorInner.style.maxHeight = `${totalHeight}px`;
  }

  toggleColumn(mortgageId, show) {
    const header = document.querySelector(
      `th[data-mortgage-id="${mortgageId}"]`
    );
    const cells = this.comparatorInner.querySelectorAll(
      `td[data-mortgage-id="${mortgageId}"]`
    );

    if (header) {
      header.style.display = show ? '' : 'none';
    }

    cells.forEach((cell) => {
      cell.style.display = show ? '' : 'none';
    });
  }
}

document.addEventListener('DOMContentLoaded', function () {
  new MortgageTableManager();
});
